import api from 'api';
import {get} from 'lodash';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import toast from 'react-hot-toast';
import {QueryFunctionContext, useQuery} from 'react-query';
import {useRouteMatch} from 'react-router-dom';
import {BlogResponse} from 'types';

interface FormValues {
  title: string;
  content: string;
  image: string;
}

async function getBlog({
  queryKey,
}: QueryFunctionContext): Promise<BlogResponse> {
  const [, slug] = queryKey;
  const {data} = await api.get<BlogResponse>(`/support/blogs/${slug}/`);
  return data as BlogResponse;
}

function useFormComponent() {
  const [image, setImage] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const {params} = useRouteMatch<{slug: string}>();

  const {data} = useQuery(['blog_details', params.slug], getBlog);

  const {
    control,
    handleSubmit,
    setValue: setFieldValue,

    formState: {errors},
  } = useForm<FormValues>({
    defaultValues: {
      title: data?.title || '',
      content: data?.content || '',
      image: data?.image as any,
    },
  });

  const createHandler = (data: FormValues) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('slug', data.title.toLowerCase().replace(/ /g, '-'));
    formData.append('content', data.content);
    if (image) {
      formData.append('image.file', image);
    }
    api
      .put(`/support/blogs/${params.slug}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        toast.success('Blog updated successfully');
        setLoading(false);
      })
      .catch((err) => {
        toast.error(get(err, 'response.data.message', 'Something went wrong'));
        setLoading(false);
      });
  };

  return {
    image,
    setImage,
    control,
    errors,
    loading,
    data,
    createHandler: handleSubmit(createHandler),
    setFieldValue,
  };
}

export default useFormComponent;
