// import Button from 'components/Button';
import DataTable from 'components/DataTable';
import ListActionsWrapper from 'components/ListActionsWrapper';
import ListWrapper from 'components/ListWrapper';
import SearchInput from 'components/SearchInput';
import PageHeader from 'components/PageHeader';
import Typography from 'components/Typography';

import useEmployees from './usePrescribers';
import ClickablePagination from 'components/ClickablePagination';
import styles from '../Settings.module.scss';
import {DataTableSchemaItem} from 'types';
import ListTabs from 'pages/UserForm/ListTabs';

const SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'name',
    header: 'Prescriber Name',
    sortable: true,
  },
  {
    dataKey: 'phone',
    header: 'Phone Number',
    sortable: true,
  },
  {
    dataKey: 'npi_no',
    header: 'NPI',
    sortable: true,
  },
  {
    dataKey: 'organisation',
    header: 'Organization',
  },
  {
    dataKey: 'active',
    header: 'Status',
    align: 'right',
  },
  {
    dataKey: 'created_at',
    header: 'Date',
    align: 'right',
    sortable: true,
  },
  {dataKey: 'view', header: ''},
];

const TABS = [
  {title: 'All', value: 'all'},
  {title: 'To Invite', value: 'to_invite'},
  {title: 'Registered', value: 'registered'},
  {title: 'Active', value: 'active'},
];

function Employees() {
  const {
    data,
    isLoading,
    sortBy,
    sortOrder,
    onSort,
    keyWord,
    setKeyWord,
    currentPage,
    setCurrentPage,
    count,
    status,
    setStatus,
  } = useEmployees();

  return (
    <div className={styles.main_container}>
      <PageHeader>
        <Typography variant="h2">Prescribers</Typography>
        {/* <Button to="/settings/employees/new" component="a" color="green">
          New Employee
        </Button> */}
        <Typography textAlign="right" variant="h4">
          Total Prescriber Count: {count}
        </Typography>
      </PageHeader>

      <ListWrapper>
        <ListTabs
          tab="presriber_tab"
          tabs={TABS}
          activeTabValue={status}
          onChange={setStatus}
        />
        <ListActionsWrapper className={styles.list_header}>
          <SearchInput
            value={keyWord}
            onChange={(val) => {
              setKeyWord(val.target.value);
              setCurrentPage(1);
              sessionStorage.setItem(`search_employe`, val.target.value);
            }}
            size="sm"
          />
        </ListActionsWrapper>
        <DataTable
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSort={onSort}
          selectable={false}
          data={data}
          schema={SCHEMA}
          loading={isLoading}
        />
        <ClickablePagination
          page={currentPage}
          total={Math.round((count || 0) / 20)}
          setCurrentPage={setCurrentPage}
        />
      </ListWrapper>
    </div>
  );
}

export default Employees;
