import useForm from './useForm';
import TextField from 'components/TextField';
import styles from './blogs.module.scss';
import ProductImage from 'components/ProductImage';
import Button from 'components/Button';
import Ckeditor from './Ckeditor';
import {Controller} from 'react-hook-form';
import classNames from 'classnames';
import Spinner from 'components/Spinner';
import {useState} from 'react';
import Modal from 'components/Modal';
import Typography from 'components/Typography';
import api from 'api';
import {useHistory} from 'react-router-dom';
import toast from 'react-hot-toast';
import {get} from 'lodash';

function Index() {
  const {goBack} = useHistory();
  const {
    data,
    loading,
    setImage,
    control,
    errors,
    setFieldValue,
    createHandler,
  } = useForm();
  const [deleteVisible, setDeleteVisible] = useState(false);

  const deleteHandler = async () => {
    if (!data) return;
    try {
      await api.delete(`/support/blogs/${data.slug}`);
      setDeleteVisible(false);
      goBack();
    } catch (error) {
      toast.error(get(error, 'response.data.message', 'Failed to delete blog'));
    }
  };

  if (!data)
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </div>
    );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.left}>
          <Controller
            control={control}
            name="title"
            rules={{required: 'Required'}}
            render={({field}) => (
              <TextField
                label="Title"
                placeholder="Title"
                value={field.value}
                wrapperClass={styles.title}
                onChange={field.onChange}
                error={errors.title?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="content"
            rules={{required: 'Required'}}
            render={({field}) => (
              <Ckeditor
                field={field}
                form={{
                  errors,
                  setFieldValue,
                }}
              />
            )}
          />
        </div>
        <div className={styles.right}>
          <div style={{marginBottom: '8px'}}>Image:</div>
          <div
            className={classNames(styles.image, errors.image && styles.error)}
          >
            <Controller
              control={control}
              name="image"
              rules={{required: 'Required'}}
              render={({field}) => (
                <ProductImage
                  showDelete={true}
                  size="medium"
                  product={{
                    ...data,
                    name: data.title,
                    id: data.id,
                    description: '',
                    strength: '',
                    price: '',
                    image: field.value as any,
                    price_currency: '',
                  }}
                  onChange={(value) => {
                    setImage(value);
                    field.onChange(value);
                  }}
                />
              )}
            />
          </div>
          {errors.image && (
            <div className={styles.errorMessage}>{errors.image.message}</div>
          )}
          <Button
            onClick={() => setDeleteVisible(true)}
            color="danger"
            style={{width: '100%', marginTop: 30}}
          >
            Delete post
          </Button>
          <Button
            loading={loading}
            onClick={createHandler}
            color="blue"
            style={{width: '100%', marginTop: 12}}
          >
            Update
          </Button>
        </div>
      </div>
      <Modal
        className={styles.modal}
        isOpen={deleteVisible}
        onRequestClose={() => setDeleteVisible(false)}
        onClose={() => setDeleteVisible(false)}
      >
        <Typography variant="h2">
          Are you sure you want to delete this blog?
        </Typography>
        <Typography className={styles.deleteText} variant="p">
          This action is irreversible and will permanently delete the blog.
        </Typography>
        <Button
          className={styles.deleteButton}
          onClick={deleteHandler}
          color="danger"
        >
          Delete
        </Button>
      </Modal>
    </>
  );
}

export default Index;
