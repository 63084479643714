import {Image} from './Image';

export enum SupportRequestStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}
export interface SupportRequest {
  id: number;
  new_leaf_order_number?: number;
  customer: string;
  created_at: string;
  phone: string;
  status: SupportRequestStatus;
}
export interface BlogResponse {
  id: number;
  slug: string;
  title: string;
  content: string;
  created_at: Date;
  image: Image;
}

export const supportRequestStatusMap: Record<
  string,
  {title: string; color: string}
> = {
  [SupportRequestStatus.ACTIVE]: {
    title: 'Unresolved',
    color: 'gray',
  },
  [SupportRequestStatus.ARCHIVED]: {
    title: 'Resolved',
    color: 'iris',
  },
};
