import api from 'api';
import ViewDetails from 'components/ViewDetails';
import {format} from 'date-fns';
import {useState} from 'react';
import {QueryFunctionContext, useQuery} from 'react-query';
import {BlogResponse, ListResponse} from 'types';

async function getBlogs({queryKey}: QueryFunctionContext<string[]>) {
  const [, page, search, status] = queryKey;
  const {data} = await api.get<ListResponse<BlogResponse>>('/support/blogs/', {
    params: {page, offset: +page * 20, limit: 20, search, status},
  });
  return data;
}

const transformData = (data: BlogResponse[]) => {
  return data.map((item) => ({
    ...item,
    image: item.image ? (
      <img
        style={{width: 70, height: 40}}
        alt={item.title}
        src={item.image.medium}
      />
    ) : null,
    created_at: format(new Date(item.created_at), 'MM/dd/yyyy hh:mm a'),
    view: <ViewDetails disabled to={`/blogs/${item.id}`} />,
  }));
};

function useBlogs() {
  const [currentPage, setCurrentPage] = useState(parseInt('1'));

  const {data, isLoading} = useQuery({
    queryKey: ['blogs', String(currentPage - 1)],
    queryFn: getBlogs,
  });

  return {
    data: data?.results ? transformData(data.results) : [],
    isLoading,
    totalPages:
      data?.count && data.limit ? Math.ceil(data.count / data.limit) : 0,
    currentPage,
    setCurrentPage,
  };
}

export default useBlogs;
