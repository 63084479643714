import DataTable from 'components/DataTable';
import ListWrapper from 'components/ListWrapper';
import Typography from 'components/Typography';
import {DataTableSchemaItem} from 'types';
import useBlogs from './useBlogs';
import Pagination from 'components/Pagination';
import Button from 'components/Button';
import styles from './blogs.module.scss';
import {useHistory} from 'react-router-dom';

const SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'title',
    header: 'Title',
  },
  {dataKey: 'image', header: 'Image'},
  {dataKey: 'created_at', header: 'Created date'},
  {dataKey: 'view', header: ''},
];

function Index() {
  const history = useHistory();
  const {data, isLoading, currentPage, setCurrentPage, totalPages} = useBlogs();

  return (
    <>
      <div className={styles.header}>
        <Typography variant="h2">Blogs</Typography>
        <Button component="a" to="/blogs/create" color="blue">
          Add Blog
        </Button>
      </div>
      <ListWrapper>
        <DataTable
          schema={SCHEMA}
          data={data}
          loading={isLoading}
          onClick={(row) => history.push(`/blogs/${row.slug}`)}
        />
        {totalPages > 1 && (
          <Pagination
            basePath="/blogs"
            page={currentPage}
            total={totalPages}
            setCurrentPage={setCurrentPage}
          />
        )}
      </ListWrapper>
    </>
  );
}

export default Index;
