function MenuBar() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6H6"
        stroke="#28303F"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18 10H6"
        stroke="#28303F"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18 14H6"
        stroke="#28303F"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default MenuBar;
