import useForm from './useForm';
import TextField from 'components/TextField';
import styles from './blogs.module.scss';
import ProductImage from 'components/ProductImage';
import Button from 'components/Button';
import Ckeditor from './Ckeditor';
import {Controller} from 'react-hook-form';
import classNames from 'classnames';

function Index() {
  const {loading, setImage, control, errors, setFieldValue, createHandler} =
    useForm();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.left}>
          <Controller
            control={control}
            name="title"
            rules={{required: 'Required'}}
            render={({field}) => (
              <TextField
                label="Title"
                placeholder="Title"
                value={field.value}
                wrapperClass={styles.title}
                onChange={field.onChange}
                error={errors.title?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="content"
            rules={{required: 'Required'}}
            render={({field}) => (
              <Ckeditor
                field={field}
                form={{
                  errors,
                  setFieldValue,
                }}
              />
            )}
          />
        </div>
        <div className={styles.right}>
          <div style={{marginBottom: '8px'}}>Image:</div>
          <div
            className={classNames(styles.image, errors.image && styles.error)}
          >
            <Controller
              control={control}
              name="image"
              rules={{required: 'Required'}}
              render={({field}) => (
                <ProductImage
                  showDelete={true}
                  size="medium"
                  product={undefined}
                  onChange={(value) => {
                    setImage(value);
                    field.onChange(value);
                  }}
                />
              )}
            />
          </div>
          {errors.image && (
            <div className={styles.errorMessage}>{errors.image.message}</div>
          )}
          <Button
            loading={loading}
            onClick={createHandler}
            color="blue"
            style={{width: '100%', marginTop: 30}}
          >
            Create
          </Button>
        </div>
      </div>
    </>
  );
}

export default Index;
