import {Link} from 'react-router-dom';

import styles from './ViewDetails.module.scss';

interface Props {
  to: string;
  disabled?: boolean;
}

function ViewDetails({to, disabled = false}: Props) {
  return (
    <div className={styles.root}>
      {disabled ? (
        <svg
          width="59"
          height="20"
          viewBox="0 0 59 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.90039 13.5H11.2271L13.9878 5.74951H12.6934L10.5771 12.1626H10.5503L8.43408 5.74951H7.13965L9.90039 13.5ZM14.8472 13.5H16.0503V5.74951H14.8472V13.5ZM17.479 13.5H22.3989V12.4634H18.6821V10.0571H22.2002V9.04736H18.6821V6.78613H22.3989V5.74951H17.479V13.5ZM25.2456 13.5H26.4004L28.0708 7.65625H28.0977L29.7627 13.5H30.9175L33.0122 5.74951H31.7607L30.3159 11.8242H30.2891L28.6509 5.74951H27.5122L25.874 11.8242H25.8525L24.4077 5.74951H23.1562L25.2456 13.5Z"
            fill="#CCCCCC"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40 10C40 9.72386 40.2239 9.5 40.5 9.5H51.5C51.7761 9.5 52 9.72386 52 10C52 10.2761 51.7761 10.5 51.5 10.5H40.5C40.2239 10.5 40 10.2761 40 10Z"
            fill="#0072B3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.6464 5.14645C46.8417 4.95118 47.1583 4.95118 47.3536 5.14645L51.8536 9.64645C52.0488 9.84171 52.0488 10.1583 51.8536 10.3536L47.3536 14.8536C47.1583 15.0488 46.8417 15.0488 46.6464 14.8536C46.4512 14.6583 46.4512 14.3417 46.6464 14.1464L50.7929 10L46.6464 5.85355C46.4512 5.65829 46.4512 5.34171 46.6464 5.14645Z"
            fill="#0072B3"
          />
          <rect
            x="0.5"
            y="0.5"
            width="58"
            height="19"
            rx="9.5"
            stroke="#EEEEEE"
          />
        </svg>
      ) : (
        <Link
          onClick={(event) => {
            event.stopPropagation();
          }}
          to={to}
        >
          <svg
            width="59"
            height="20"
            viewBox="0 0 59 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.90039 13.5H11.2271L13.9878 5.74951H12.6934L10.5771 12.1626H10.5503L8.43408 5.74951H7.13965L9.90039 13.5ZM14.8472 13.5H16.0503V5.74951H14.8472V13.5ZM17.479 13.5H22.3989V12.4634H18.6821V10.0571H22.2002V9.04736H18.6821V6.78613H22.3989V5.74951H17.479V13.5ZM25.2456 13.5H26.4004L28.0708 7.65625H28.0977L29.7627 13.5H30.9175L33.0122 5.74951H31.7607L30.3159 11.8242H30.2891L28.6509 5.74951H27.5122L25.874 11.8242H25.8525L24.4077 5.74951H23.1562L25.2456 13.5Z"
              fill="#CCCCCC"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M40 10C40 9.72386 40.2239 9.5 40.5 9.5H51.5C51.7761 9.5 52 9.72386 52 10C52 10.2761 51.7761 10.5 51.5 10.5H40.5C40.2239 10.5 40 10.2761 40 10Z"
              fill="#0072B3"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M46.6464 5.14645C46.8417 4.95118 47.1583 4.95118 47.3536 5.14645L51.8536 9.64645C52.0488 9.84171 52.0488 10.1583 51.8536 10.3536L47.3536 14.8536C47.1583 15.0488 46.8417 15.0488 46.6464 14.8536C46.4512 14.6583 46.4512 14.3417 46.6464 14.1464L50.7929 10L46.6464 5.85355C46.4512 5.65829 46.4512 5.34171 46.6464 5.14645Z"
              fill="#0072B3"
            />
            <rect
              x="0.5"
              y="0.5"
              width="58"
              height="19"
              rx="9.5"
              stroke="#EEEEEE"
            />
          </svg>
        </Link>
      )}
    </div>
  );
}

export default ViewDetails;
