import {useState} from 'react';
import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {useRouteMatch} from 'react-router-dom';
import {formatISO} from 'date-fns';
import {findLast} from 'lodash';

import api from 'api';
import {
  MMScriptStatus,
  Note,
  RequestDetails,
  ScriptStatus,
  SelectOption,
} from 'types';
import {ScriptStatusOption} from 'components/ScriptStatusSelect';

import {data as dummyData} from './dummy';
import {getFullName} from 'helpers/user';
import useCurrentUser from 'hooks/useCurrentUser';
import {ActionMeta, SingleValue} from 'react-select';
import useCustomerInsurances from 'hooks/useCustomerInsurances';
import toast from 'react-hot-toast';

async function getRequestById({
  queryKey,
}: QueryFunctionContext): Promise<RequestDetails> {
  const [, id, order, status] = queryKey;
  const {data} = await api.get<RequestDetails>(`/requests/${id}/`, {
    params: {order, status},
  });
  return {...dummyData, ...data} as RequestDetails;
}

function useRequestDetails(statusQuery: string) {
  const {params} = useRouteMatch<{id: string}>();
  const currentUser = useCurrentUser();
  const [showInsurance, setShowInsurance] = useState(false);
  const queryClient = useQueryClient();
  const {insurances} = useCustomerInsurances({});

  const queryCach = queryClient.getQueryCache().getAll();

  const [, , order, status] =
    (findLast(queryCach, (q) => q.queryKey[0] === 'requests')
      ?.queryKey as string[]) || [];

  const {data, isLoading, refetch} = useQuery(
    ['request_details', params.id, order, status],
    getRequestById,
    {
      refetchInterval:
        statusQuery === 'billed' || statusQuery === 'ready_for_delivery'
          ? 3000
          : false,
    }
  );

  const completeRequest = useMutation(
    'complete_request',
    async () => {
      await api.post(`/requests/${params.id}/complete/`);
      return;
    },
    {
      // onMutate: async () => {
      //   await queryClient.cancelQueries(['request_details', id]);

      //   const previousRequest = queryClient.getQueryData<RequestDetails>([
      //     'request_details',
      //     id,
      //   ]);

      //   if (!previousRequest) throw Error('Request is `undefined`');

      //   const nextRequest: RequestDetails = {
      //     ...previousRequest,
      //     status: 'completed',
      //   };

      //   queryClient.setQueryData(['request_details', id], nextRequest);

      //   return {previousRequest};
      // },
      onSuccess: () => {
        queryClient.invalidateQueries(['request_details']);
      },
    }
  );

  const updateScriptStatus = useMutation(
    'update_script_status',
    async ({
      drugId,
      status,
    }: {
      drugId: number;
      status: ScriptStatus | MMScriptStatus;
    }) => {
      const {data} = await api.post<{status: ScriptStatus}>(
        `/requests/${params.id}/prescriptions/${drugId}/status/`,
        {status}
      );
      return {...data, drugId};
    },
    {
      // onMutate: async ({ drugId, status }) => {
      //   await queryClient.cancelQueries(["request_details", id]);

      //   const previousRequest = queryClient.getQueryData<RequestDetails>([
      //     "request_details",
      //     id,
      //   ]);

      //   if (!previousRequest) throw Error("Request is `undefined`");

      //   const nextRequest: RequestDetails = {
      //     ...previousRequest,
      //     prescriptions: previousRequest.prescriptions.map((drug) =>
      //       drug.id === drugId ? { ...drug, status } : drug
      //     ),
      //   };

      //   queryClient.setQueryData(["request_details", id], nextRequest);

      //   return { previousRequest };
      // },
      onSettled: () => {
        queryClient.invalidateQueries(['request_details', params.id]);
      },
    }
  );

  const updatePreference = useMutation(
    'update_preference',
    async (datas: {
      preference_date: string | null;
      preference_time: string | null;
    }) => {
      const {data} = await api.patch<{
        preference_date: string;
        preference_time: string;
      }>(`/requests/${params.id}/`, datas);
      return {...data};
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['request_details', params.id]);
      },
    }
  );

  const createNote = useMutation(
    'create_note',
    async ({
      text,
      prescriber,
    }: {
      text: string;
      prescriber: boolean;
    }): Promise<string> => {
      const {data} = await api.post<{
        text: string;
        is_visible_to_prescribers: boolean;
      }>(`/requests/${params.id}/note/`, {
        text,
        is_visible_to_prescribers: prescriber,
      });
      return data.text;
    },
    {
      onMutate: async ({text, prescriber}) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(['request_details', params.id]);

        // Snapshot the previous value
        const previousRequest = queryClient.getQueryData<RequestDetails>([
          'request_details',
          params.id,
        ]);

        const newNote: Note = {
          id: Date.now(),
          text,
          is_visible_to_prescribers: prescriber,
          author: getFullName(currentUser),
          avatar: currentUser.avatar,
          created_at: formatISO(Date.now()),
        };

        const nextRequest: RequestDetails | undefined = previousRequest
          ? {
              ...previousRequest,
              notes: [newNote, ...previousRequest.notes],
            }
          : undefined;

        // Optimistically update to the new value
        queryClient.setQueryData(['request_details', params.id], nextRequest);

        // Return a context object with the snapshotted value
        return {previousRequest};
      },

      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, _text, context?: {previousRequest?: RequestDetails}) => {
        queryClient.setQueryData(
          ['request_details', params.id],
          context?.previousRequest
        );
      },

      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(['request_details', params.id]);
      },
    }
  );

  function onChangeTime(value: {
    preference_date: string | null;
    preference_time: string | null;
  }) {
    if (!data) return;

    updatePreference.mutate({
      ...value,
    });
  }

  function onChangeScriptStatus(
    newValue: SingleValue<ScriptStatusOption>,
    actionMeta: ActionMeta<SelectOption>
  ) {
    const drugId = Number(actionMeta.name?.split('-')[1]);
    if (newValue) {
      const status = newValue.value;
      updateScriptStatus.mutate({drugId, status});
    }
  }

  function onSubmitNote(text: string, prescriber: boolean) {
    return createNote.mutate({text, prescriber});
  }

  function complete() {
    completeRequest.mutateAsync();
  }

  function onStatusChange() {
    if (!data) {
      return;
    }
    api
      .patch(`/requests/${data.id}/`, {is_resolved: !data.is_resolved})
      .then(() => {
        refetch();
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.error_message || 'Something went wrong'
        );
      });
  }

  async function chargeCard(datas: any) {
    const data = await api.post<any>(`/payment/charge/`, datas);
    refetch();
    return data;
  }

  return {
    data,
    id: params.id,
    showInsurance,
    insurances,
    isLoading,

    markDisabled: Boolean(
      data?.prescriptions.some(
        (drug) =>
          drug.status !== 'ready_for_delivery' &&
          drug.status !== 'cancel_script'
      )
    ),

    setShowInsurance,
    onSubmitNote,
    onChangeScriptStatus,
    complete,
    chargeCard,
    onChangeTime,
    refetch,
    onStatusChange,
  };
}

export default useRequestDetails;
